$(document).ready(function () {
    matchHeight();
    handleCarousel();
    gallery();
    handleTable();
    handleQuicklink();
    navigation();

    setTimeout(handleLoader, 800);

    $('body').addClass("loading");

    $(window).resize(function() {
        handleQuicklink();
    });
});

$(window).on("load", function() {
    $('body').addClass("loaded");
    $('body').removeClass("loading");

    handleFooter();
    $(window).trigger('resize');
});

function navigation() {
    $(".dropdown").on("show.bs.dropdown", function(event){
        // var x = $(event.relatedTarget).text(); // Get the text of the element
        // alert(x);
        $("body").addClass("dropdown-visible");
        var target = $(event.relatedTarget).next(".dropdown-menu");
        var offset = target.offset();
    });
    $(".dropdown").on("hide.bs.dropdown", function(event){
        // var x = $(event.relatedTarget).text(); // Get the text of the element
        // alert(x);
        $("body").removeClass("dropdown-visible");
    });
}

function handleQuicklink() {
    var element = $('.quicklink');
    var tileWidth = element.outerWidth( true );
    var tileHeight = tileWidth;

    element.height(tileHeight)
}

function matchHeight() {
    // $('.element').matchHeight();
}

function handleCarousel() {
    $('.owl-carousel').owlCarousel({
        dots: true,
        nav: true,
        mouseDrag: false,
        autoplay: true,
        autoplayTimeout: 700000,
        autoplayHoverPause: true,
        // loop: true,
        responsive : {

            0 : {
                items:1,
                stagePadding: 0

            },

            576 : {
                items:1,
                stagePadding: 0
            },

            1920 : {
                items:1,
                stagePadding: 300
            },

            1921 : {
                items:1,
                stagePadding: 0
            }
        }
    })

    $('.owl-carousel').on('changed.owl.carousel', function(e) {

        var pageCount = e.page.count;
        var page = e.page.index;

        if(page === (pageCount -1)){
            $(this).find('.owl-stage').addClass('no-offset');
        } else {
            $(this).find('.owl-stage').removeClass('no-offset');
        }

    });
}

function gallery() {

    $(".gallery").each(function(index,item) {

        $(this).magnificPopup({
            delegate: 'a',
            type: 'image',
            tLoading: 'Loading image #%curr%...',
            mainClass: 'mfp-img-mobile mfp-fade',
            gallery: {
                enabled: true,
                navigateByImgClick: true,
                preload: [0, 1], // Will preload 0 - before current, and 1 after the current image
                tCounter: '%curr% van %total%' // Markup for "1 of 7" counter
            },
            image: {
                tError: '<a href="%url%">The image #%curr%</a> could not be loaded.',
                titleSrc: function (item) {
                    return item.el.attr('title');
                }
            },
            removalDelay: 300
        });

        var galleryLength = $(item).find("a").length;

        if (galleryLength == 1) {
            $(item).addClass('one-image');
        } else if (galleryLength == 2) {
            $(item).addClass('two-image');
        } else if (galleryLength == 3) {
            $(item).addClass('three-image');
        } else if (galleryLength >= 4) {
            $(item).addClass('more');
        }
    });
}

function handleFooter() {
    $(window).resize(function() {
        var contentHeight = Math.round($("body").innerHeight());
        var windowWidth= $(window).innerWidth();
        var windowHeight = window.innerHeight;

        if((windowWidth >= 768) && ( contentHeight <= windowHeight)){
            $("footer").addClass("sticky");
        } else {
            $("footer").removeClass("sticky");
        }
    });
}

function handleTable() {
    $('table').addClass('table table-striped');
}

function handleLoader() {
    $('#loading-bar-spinner').toggle();
}

window.onload = function() {

    var placeholder = $("figure.figure-progressive");

    placeholder.each(function() {
        var small = this.querySelector('.img-small');
        var imageClass = this.dataset.rounded;

        //load small image and show it
        var img = new Image();
        img.src = small.src;
        img.onload = function () {
            small.classList.add('loaded');
        };

        //load large image
        var imgLarge = new Image();
        imgLarge.src = this.dataset.large;
        imgLarge.srcset = this.dataset.srcset;
        imgLarge.onload = function () {
            imgLarge.classList.add('loaded');
        };
        this.classList.add(imageClass);
        this.appendChild(imgLarge);

    });
};