//aanroep van de cookie plugin
window.addEventListener("load", function(){
	window.cookieconsent.initialise({
		palette: {
			popup: {
				background: "#eaf7f7",
				text: "#555555"
			},
			button: {
				background: "#0071b9"
			}
		},

		position: "bottom-right",
		type: "opt-in",
		content: {
			message: "Deze website maakt gebruik van cookies om ervoor te zorgen dat u de beste ervaring op onze website krijgt.",
			dismiss: "Sluiten",
			allow: "Akkoord",
			link: "Lees verder",
			href: location.protocol+"//"+location.hostname+'/privacy-verklaring'
		},
		onInitialise: function (status) {
			var type = this.options.type;
			var didConsent = this.hasConsented();
			if (type == 'opt-in' && didConsent) {
				$(document).trigger('cookie-enabled');
			}
			if (type == 'opt-out' && !didConsent) {
				$(document).trigger('cookie-disabled');
			}
		},

		onStatusChange: function(status, chosenBefore) {
			var type = this.options.type;
			var didConsent = this.hasConsented();
			if (type == 'opt-in' && didConsent) {
				$(document).trigger('cookie-enabled');
			}
			if (type == 'opt-out' && !didConsent) {
				$(document).trigger('cookie-disabled');
			}
		},

		onRevokeChoice: function() {
			var type = this.options.type;
			if (type == 'opt-in') {
				$(document).trigger('cookie-disabled');
			}
			if (type == 'opt-out') {
				$(document).trigger('cookie-enabled');
			}
		}
	})});